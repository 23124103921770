[class^="internal-"] {
  @include underline-effect(1px);
  font-size: toRem(14);
  line-height: calc(14 / 13);

  &:after {
    display: inline-block;
    margin-left: 8px;
    position: relative;
  }
}

.internal-link {
  background-position: calc(100% - 21px) 100%, 0 100% !important;
  &:after {
    content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxMyAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0xMi41MzQzIDcuMTA3NzRMNi4xMDQzOCAwLjc4NDE4TDUuMTAxNzQgMS43NzAyNEw5Ljg2NjU1IDYuNDU2MjRIMFY3Ljg1NDg1SDkuOTE1NzZMNS4xOTMwOSAxMi40OTk0TDYuMTk1NzMgMTMuNDg1NUwxMi42MDY1IDcuMTgwN0wxMi41MzMzIDcuMTA4NjlMMTIuNTM0MyA3LjEwNzc0WiIgZmlsbD0iIzFBMUExQSIvPg0KPC9zdmc+DQo=");
    width: 13px;
    height: 14px;
    top: calc(3 / 13) * 1em;
  }
}

.internal-download {
  background-position: calc(100% - 26px) 100%, 0 100% !important;
  &:after {
    content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNy41IDcuNDQ0djkuNjlIMHYtOS42OWgxLjV2OC4xOUgxNnYtOC4xOWgxLjVabS0zLjc2LjUxNS00Ljk5IDUuMjgzLTQuOTktNS4yODMgMS4wOS0xLjAzTDggMTAuMjYzVi4zODVoMS41djkuODc4bDMuMTUtMy4zMzQgMS4wOSAxLjAzWiIgZmlsbD0iIzFBMUExQSIvPjwvc3ZnPg==");
    width: 18px;
    height: 18px;
    top: calc(4 / 13) * 1em;
  }
}

.internal-modal {
  background-position: calc(100% - 24px) 100%, 0 100% !important;
  &:after {
    content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xIC41NjNILjI1djE0Ljc3NmgxNC43NzZ2LTMuODk1aC0xLjV2Mi4zOTVIMS43NVYyLjA2MmgyLjM5NHYtMS41SDFabTcuMDc0IDEuNWg0LjM5M0wzLjQ0IDExLjA4OGwxLjA2MSAxLjA2IDkuMDI0LTkuMDI0djQuMzAxaDEuNVYuNTYyaC02Ljk1djEuNVoiIGZpbGw9IiMxQTFBMUEiLz48L3N2Zz4=");
    width: 16px;
    height: 16px;
    top: calc(2 / 13) * 1em;
  }
}

@media (min-width: map-get($breakpoints, "xxxxl")) {
  [class^="internal-"] {
    font-size: toRem(14);
  }

  /*   .internal-download {
    &:after {
      top: toRem(-2);
    }
  } */
}
