@mixin table {
  table {
    border-color: $line;
    font-size: toRem(14);
    width: 100%;

    tbody,
    td,
    th,
    thead,
    tr {
      border-color: inherit;
      border-style: solid;
    }

    > {
      :not(caption) {
        > * {
          border-width: 1px 0;
          > * {
            border-width: 0 1px;
            padding-left: toRem(18);
            padding-right: toRem(18);
            word-wrap: break-word;
            overflow-wrap: break-word;
            hyphens: auto;
          }
        }
      }
    }

    @media (min-width: map-get($breakpoints, "sm")) {
      table-layout: fixed;
    }
  }

  thead {
    color: $text-color-secondary;
    > * > * {
      padding-top: toRem(8);
      padding-bottom: toRem(8);
      white-space: nowrap;
    }
  }

  tbody {
    > * > * {
      padding-top: toRem(13);
      padding-bottom: toRem(13);
    }
  }
}
