// https://nickymeuleman.netlify.app/blog/css-animated-wrapping-underline

@mixin underline-effect($underline-thickness) {
  background-image: linear-gradient(
    to left,
    rgba(26, 26, 26, 1),
    rgba(26, 26, 26, 1)
  );
  background-size: 100% $underline-thickness, 0 $underline-thickness;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 300ms;

  &:hover {
    color: inherit;
  }

  &:hover,
  &:focus {
    background-size: 0 $underline-thickness, 0 $underline-thickness;
  }
}
