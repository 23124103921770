/// Retorna clases para alineación de texto
/// @param {Array} $aligns
/// @return {text-align: $align}
$aligns: left, center, right;

.text {
  @each $align in $aligns {
    &-#{$align} {
      text-align: $align;
    }
  }
}
