@import "../../styles/utils/variables/colors";
@import "../../styles/utils/variables/borders";
@import "../../styles/utils/variables/decoration";
@import "../../styles/utils/variables/fonts";
@import "../../styles/utils/variables/footer";
@import "../../styles/utils/variables/grid";
@import "../../styles/utils/variables/mediaquerys";
@import "../../styles/utils/variables/menu";
@import "../../styles/utils/variables/percentDesc";

$escaped-characters: (
  ("<", "%3c"),
  (">", "%3e"),
  ("#", "%23"),
  ("(", "%28"),
  (")", "%29")
);

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }

  @return $string;
}

@function escape-svg($string) {
  @if str-index($string, "data:image/svg+xml") {
    @each $char, $encoded in $escaped-characters {
      // Do not escape the url brackets
      @if str-index($string, "url(") == 1 {
        $string: url("#{str-replace(str-slice($string, 6, -3), $char, $encoded)}");
      } @else {
        $string: str-replace($string, $char, $encoded);
      }
    }
  }

  @return $string;
}

@import "../../styles/utils/mixins/include-media";
@import "../../styles/utils/mixins/align-text";
@import "../../styles/utils/mixins/apply-increments";
@import "../../styles/utils/mixins/basic-transition";
@import "../../styles/utils/mixins/colors";
@import "../../styles/utils/mixins/event-wrapper";
@import "../../styles/utils/mixins/font-family";
@import "../../styles/utils/mixins/font-size";
@import "../../styles/utils/mixins/grid-padding";
@import "../../styles/utils/mixins/grid-padding-left";
@import "../../styles/utils/mixins/grid-padding-right";
@import "../../styles/utils/mixins/screen-height";
@import "../../styles/utils/mixins/margin-bottom";
@import "../../styles/utils/mixins/padding-left";
@import "../../styles/utils/mixins/rem-converter";
@import "../../styles/utils/mixins/leading-trim";
@import "../../styles/utils/mixins/headings";
@import "../../styles/utils/mixins/underline-effect";
@import "../../styles/utils/mixins/lists";
@import "../../styles/utils/mixins/tables";

// FAMILIES
%bold {
  font-family: "graphikbold";
}

%semibold {
  font-family: "graphiksemibold";
}

%medium {
  font-family: "graphikmedium";
}

%regular {
  font-family: "graphik_regularregular";
}

%light {
  font-family: "graphiklight";
}

// FONT VARIANTS
%bold-italic {
  font-family: "graphikbold_italic";
}

%semibold-italic {
  font-family: "graphiksemibold_italic";
}

%medium-italic {
  font-family: "graphikmedium_italic";
}

%regular-italic {
  font-family: "graphik_regularregular_-";
}

%light-italic {
  font-family: "graphiklight_italic";
}

// FONT-SIZES
%xxl {
  @include fontSize(xxl);
  line-height: 110%;
}
%xl {
  @include fontSize(xl);
  line-height: 110%;
}

%l {
  @include fontSize(l);
  line-height: 120%;
}

%m {
  @include fontSize(m);
  line-height: 110%;
}

%sm {
  @include fontSize(sm);
  line-height: 110%;
}

%xs {
  @include fontSize(body_l);
  line-height: 110%;
}

%body-l {
  @include fontSize(body_l);
  line-height: 120%;
}

%body-m {
  @include fontSize(body_m);
  line-height: var(--line-height);
}

%body-s {
  @include fontSize(body_s);
  line-height: var(--line-height);
}

%body-xs {
  @include fontSize(body_xs);
  line-height: var(--line-height);
}

// PROJECT FONTS
%xxl-medium {
  @extend %xxl;
  @extend %medium;
}
%xl-bold {
  @extend %xl;
  @extend %bold;
}
%xl-medium {
  @extend %xl;
  @extend %medium;
}
%xl-semibold {
  @extend %xl;
  @extend %semibold;
}
%xl-regular {
  @extend %xl;
  @extend %regular;
}

%l-bold {
  @extend %l;
  @extend %bold;
}
%l-semibold {
  @extend %l;
  @extend %semibold;
}
%l-medium {
  @extend %l;
  @extend %medium;
}
%l-regular {
  @extend %l;
  @extend %regular;
}

%m-medium {
  @extend %m;
  @extend %medium;
}
%m-regular {
  @extend %m;
  @extend %regular;
}

%sm-medium {
  @extend %sm;
  @extend %medium;
}

%xs-semibold {
  @extend %xs;
  @extend %semibold;
}

%body-l-semibold {
  @extend %body-l;
  @extend %semibold;
}

%body-l-medium {
  @extend %body-l;
  @extend %medium;
}

%body-l-regular {
  @extend %body-l;
  @extend %regular;
}

%body-m-semibold {
  @extend %body-m;
  @extend %semibold;
}

%body-m-medium {
  @extend %body-m;
  @extend %medium;
}

%body-m-regular {
  @extend %body-m;
  @extend %regular;
}

%body-s-semibold {
  @extend %body-s;
  @extend %semibold;
}

%body-s-medium {
  @extend %body-s;
  @extend %medium;
}

%body-s-regular {
  @extend %body-s;
  @extend %regular;
}

%body-xs-semibold {
  @extend %body-xs;
  @extend %semibold;
  text-transform: uppercase;
}

%body-xs-medium {
  @extend %body-xs;
  @extend %medium;
  text-transform: uppercase;
}

%body-xs-regular {
  @extend %body-xs;
  @extend %regular;
  text-transform: uppercase;
}

%menu {
  @extend %medium;
  font-size: 11px;
  @include media(">=l") {
    font-size: 12px;
  }
  @include media(">=xl") {
    font-size: 13px;
  }
  @include media(">=xxl") {
    font-size: 14px;
  }
  @include media(">=xxxl") {
    font-size: 15px;
  }
  @include media(">=xxxxl") {
    font-size: 16px;
  }
}

%marquee {
  @include fontSize(marquer);
}

%input {
  @include fontSize(body_l);
  color: $secondary-color;
  font-weight: 400;
}

:root {
  --font-size: 14;
  --line-height: #{$line-height};
  --space-56: #{toRem(16)};

  @media (min-width: map-get($breakpoints, "sm")) {
    --space-56: #{toRem(24)};
  }

  @media (min-width: map-get($breakpoints, "md")) {
    --font-size: 16;
    --space-56: #{toRem(40)};
  }

  @media (min-width: map-get($breakpoints, "l")) {
    --space-56: #{toRem(56)};
  }
}

body {
  color: $primary-color;
  @extend %body-m-regular;
}

//$breakpoints: (xs: 320px, sm: 768px, md: 1024px, l: 1280px, xl: 1366px, xxl: 1440px,xxxl: 1680px, xxxxl: 1920px)
$padding-sizes: (
  "xxxxl": 70px,
  "xxxl": 62px,
  "xxl": 40px,
  "xl": 38px,
  "l": 36px,
  "md": 30px,
  "sm": 30px,
  "xs": 15px,
);
$padding-sizes_hero: (
  "xxxxl": 70px,
  "xxxl": 40px,
  "xxl": 40px,
  "xl": 50px,
  "l": 40px,
  "md": 35px,
  "sm": 30px,
  "xs": 15px,
);

$menu-desktop: 90px;
$menu-mobile: 66px;

%block-padding {
  @include gridPadding($padding-sizes);
}

%layout {
  margin-left: 0;
  flex: 1;

  @include media(">=md") {
    margin-left: 92px;
  }
  @include media(">=l") {
    margin-left: 100px;
  }
  @include media(">=xl") {
    margin-left: 110px;
  }
  @include media(">=xxxl") {
    margin-left: 125px;
  }
  @include media(">=xxxxl") {
    margin-left: 140px;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 15px;

  @media (min-width: map-get($breakpoints, "sm")) {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-column-gap: apply-increments(2.25);
  }
}

main {
  @media (max-width: 1023px) {
    > section:first-of-type[class*="BloqueTextoSimple"] {
      padding-top: toRem(40);
    }
  }

  @media (min-width: map-get($breakpoints, "md")) {
    > div:first-of-type {
      & + section[class*="BloqueTextoSimple"] {
        padding-top: toRem(40);
      }
    }
  }

  @media (min-width: map-get($breakpoints, "xl")) {
    > div:first-of-type {
      & + section[class*="BloqueTextoSimple"] {
        padding-top: toRem(120);
      }
    }
  }
}

.col-span-2 {
  grid-column: span 2 / span 2;
}
.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}
.col-span-5 {
  grid-column: span 5 / span 5;
}
.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-start-1 {
  grid-column-start: 1;
}

.col-end-13 {
  grid-column-end: 13;
}

@media (min-width: map-get($breakpoints, "sm")) {
  .flex-layout-sm {
    display: flex;
    flex-wrap: wrap;
  }

  .col-sm-12 {
    flex-basis: 100%;
  }

  .grid-sm {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-column-gap: apply-increments(2.25);
  }
  .col-span-sm-3 {
    grid-column: span 3 / span 3;
  }
  .col-span-sm-4 {
    grid-column: span 4 / span 4;
  }
  .col-span-sm-6 {
    grid-column: span 6 / span 6;
  }
  .col-span-sm-8 {
    grid-column: span 8 / span 8;
  }
  .col-span-sm-10 {
    grid-column: span 10 / span 10;
  }
  .col-span-sm-12 {
    grid-column: span 12 / span 12;
  }
  .col-sm-start-2 {
    grid-column-start: 2;
  }
  .col-sm-start-3 {
    grid-column-start: 3;
  }
}

@media (min-width: map-get($breakpoints, "md")) {
  .grid-md {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-column-gap: apply-increments(2.25);
  }

  .col-span-md-3 {
    grid-column: span 3 / span 3;
  }
  .col-span-md-9 {
    grid-column: span 9 / span 9;
  }
  .col-span-md-6 {
    grid-column: span 6 / span 6;
  }

  .col-md-start-6 {
    grid-column-start: 3;
  }
  .col-span-md-6 {
    grid-column: span 6 / span 6;
  }
  .col-span-md-8 {
    grid-column: span 8 / span 8;
  }
}

@media (min-width: map-get($breakpoints, "l")) {
  .grid-l {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-column-gap: apply-increments(2.25);
  }

  .col-l-start-2 {
    grid-column-start: 2;
  }

  .col-l-end-12 {
    grid-column-end: 12;
  }
  .col-span-l-9 {
    grid-column: span 9 / span 9;
  }
  .col-span-l-3 {
    grid-column: span 3 / span 3;
  }
}

@media (min-width: map-get($breakpoints, "xl")) {
  .col-xl-start-3 {
    grid-column-start: 3;
  }
  .col-span-xxl-2 {
    grid-column: span 2 / span 2;
  }
  .col-xl-end-11 {
    grid-column-end: 11;
  }
  .col-span-xl-9 {
    grid-column: span 9 / span 9;
  }
  .col-span-xl-3 {
    grid-column: span 3 / span 3;
  }
}

@media (min-width: map-get($breakpoints, "xxxl")) {
  .col-span-xxl-2 {
    grid-column: span 2 / span 2;
  }
  .col-span-xxl-2 {
    grid-column: span 2 / span 2;
  }
}

@media (min-width: map-get($breakpoints, "xxxxl")) {
  .align-items-xxxxl-center {
    align-items: center;
  }

  .grid-xxxxl {
    display: grid;
  }

  .grid,
  .grid-sm,
  .grid-md,
  .grid-l,
  .grid-xxxxl {
    grid-template-columns: repeat(24, minmax(0, 1fr));
    grid-column-gap: 40px;
  }
  .col-span-xxxxl-2 {
    grid-column: span 2 / span 2;
  }
  .col-span-xxxxl-4 {
    grid-column: span 4 / span 4;
  }
  .col-span-xxxxl-6 {
    grid-column: span 6 / span 6;
  }
  .col-span-xxxxl-8 {
    grid-column: span 8 / span 8;
  }
  .col-span-xxxxl-9 {
    grid-column: span 9 / span 9;
  }
  .col-span-xxxxl-10 {
    grid-column: span 10 / span 10;
  }
  .col-span-xxxxl-12 {
    grid-column: span 12 / span 12;
  }
  .col-xxxxl-end-20 {
    grid-column-end: 20;
  }
  .col-xxxxl-start-2 {
    grid-column-start: 2;
  }
  .col-xxxxl-start-6 {
    grid-column-start: 6;
  }

  .col-xxxxl-start-7 {
    grid-column-start: 7;
  }
  .col-xxxxl-start-8 {
    grid-column-start: 8;
  }
  .col-xxxxl-start-13 {
    grid-column-start: 13;
  }
  .col-xxxxl-start-14 {
    grid-column-start: 14;
  }
  .col-span-xxxxl-24 {
    grid-column: span 24 / span 24;
  }
  .col-span-xxxl-5 {
    grid-column: span 5 / span 5;
  }
  .col-span-xxxl-6 {
    grid-column: span 6 / span 6;
  }
  .col-span-xxxl-8 {
    grid-column: span 18 / span 18;
  }
  .col-span-xxxl-9 {
    grid-column: span 19 / span 19;
  }
  .col-xxxl-end-20 {
    grid-column-end: 20;
  }
  .col-xxxl-start-20 {
    grid-column-start: 20;
  }
}

@mixin visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.obfuscate {
  direction: ltr !important;
}

.featured-text {
  font-family: "graphiksemibold";
  font-size: toRem(16);
  font-weight: normal;
  line-height: var(--line-height);

  @media (min-width: map-get($breakpoints, "xxxxl")) {
    font-size: toRem(18);
  }
}

%before-opacity {
  &::before {
    content: "";
    background-color: rgba($primary-color, 0.2);
    inset: 0;
    position: absolute;
    z-index: 1;
  }
}

%wysiwyg-16 {
  > * {
    margin-bottom: 0;
  }

  > * + * {
    margin-top: toRem(16);
  }
}

.wysiwyg {
  img {
    display: block;
    height: auto;
    max-width: 100%;

    &:not([class]) {
      display: inline-block;
    }
  }

  figure {
    display: table;
    position: relative;

    img {
      display: block !important;
    }
  }

  figcaption {
    bottom: 0;
    position: absolute;
    width: 100%;
    text-align: center;
    color: $secondary-color;
    padding: apply-increments(2);
  }

  .align-left {
    float: left;
    padding-right: apply-increments(3);
    padding-bottom: apply-increments(3);
  }

  .align-center {
    margin-left: auto;
    margin-right: auto;
  }

  .align-right {
    float: right;
    padding-left: apply-increments(3);
    padding-bottom: apply-increments(3);
  }
}

$right-values: (
  "sm": 30px,
  "l": 36px,
  "xl": 38px,
  "xxl": 40px,
  "xxxl": 62px,
  "xxxxl": 70px,
);

@mixin right {
  @each $breakpoint, $value in $right-values {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      right: $value;
    }
  }
}

.btnActive {
  @include basicTransition(opacity);
}

.wrapper {
  --share-height: #{toRem(44)};
  --button-share-width: #{toRem(60)};

  position: sticky;
  bottom: 0;
  right: 0;
  //padding-left: 92px;
  padding-left: 0;
  pointer-events: none;
  z-index: 2;

  &.active {
    z-index: 999;

    .share__content {
      pointer-events: none;
    }

    .btnActive {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &.inFooter {
    position: sticky;
  }

  @media (min-width: map-get($breakpoints, "sm")) {
    --share-height: #{toRem(50)};
    --button-share-width: #{toRem(158)};
  }

  @media (min-width: map-get($breakpoints, "md")) {
    padding-left: 92px;
  }

  @media (min-width: map-get($breakpoints, "l")) {
    padding-left: 100px;
  }

  @media (min-width: map-get($breakpoints, "xl")) {
    padding-left: 110px;
  }

  @media (min-width: map-get($breakpoints, "xxxl")) {
    padding-left: 125px;
  }

  @media (min-width: map-get($breakpoints, "xxxxl")) {
    padding-left: 140px;
  }
}

.wrapper {
  display: block;
  flex-wrap: wrap;
}

.buttons_wrapper {
  display: flex;
  justify-content: space-between;
}

.shareblock {
  align-self: flex-end;
  display: flex;

  @include media("<sm") {
    width: 68px;
  }
}

.share {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  align-items: flex-end;
  gap: 4px;
  pointer-events: none;
  z-index: 1000;
  flex-grow: 1;

  button {
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    svg {
      flex-shrink: 0;
    }
  }

  &.active {
    pointer-events: auto;

    .overlay {
      opacity: 1;
    }

    .share__list {
      opacity: 1;
      height: auto;
      visibility: visible;
      transform: translateY(0);
    }

    .share__btn-social {
      pointer-events: auto;
    }
  }

  &__content {
    background-color: rgba($icon, 0.4);
    border-radius: 10px 10px 0px 0px;
    padding: 4px 4px 0 4px;
    height: var(--share-height);

    display: flex;
    gap: 4px;
  }

  &__btn {
    background-color: rgba($icon, 0.4);
    display: grid;
    place-items: center;
    background-color: #fff;
    border-bottom: none;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    height: 100%;
    pointer-events: auto;
    width: max-content;

    &--share {
      width: var(--button-share-width);
    }
  }

  @extend %medium;
  font-size: toRem(16);
  line-height: 150%;

  &__list {
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    transition-delay: 0s;
    transition-property: opacity, transform;
    height: 0;
    opacity: 0;
    visibility: hidden;
    background-color: rgba($icon, 0.4);
    border-radius: 10px;
    width: 100%;
    padding: 4px;
    position: absolute;
    right: -4px;
    bottom: calc(var(--share-height) + 4px);
    transform: translateY(10px);
    width: calc(100% + 8px);
    z-index: -1;

    li {
      display: flex;
      &:first-of-type {
        button {
          border-top-right-radius: 6px;
          border-top-left-radius: 6px;
        }
      }

      &:last-of-type {
        button {
          border-bottom-right-radius: 6px;
          border-bottom-left-radius: 6px;
        }
      }
    }
  }

  &__btn-social {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: toRem(42);
    width: 100%;
  }

  @media (min-width: map-get($breakpoints, "sm")) {
    gap: 8px;

    &__content {
      display: flex;
      gap: 4px;
    }

    &__btn {
      display: flex;
      align-items: center;
      padding: 0 toRem(27);
      gap: 10px;
    }

    &__btn-social {
      justify-content: flex-start;
      padding: 0 toRem(27);
      gap: 8px;
      height: toRem(46);
    }

    &__list {
      li {
        & + li {
          margin-top: 1px;
        }
      }
    }
  }
}

.return {
  @extend %medium;

  display: flex;
  align-items: flex-end;
  z-index: 1001;

  svg {
    transform: rotate(-90deg);
  }

  a {
    min-width: 100px;
  }
  @media (max-width: map-get($breakpoints, "sm")) {
    a {
      display: flex !important;
      justify-content: center;

      svg {
        margin-right: 8px;
      }
    }
  }
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  position: fixed;
  inset: 0;
  @include basicTransition(opacity);
  z-index: -1;
}

@keyframes inAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes outAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.start {
  animation: inAnimation 300ms ease-in;
}

.end {
  animation: outAnimation 300ms ease-out forwards;
}

.overlay-wrapper {
  position: fixed;
  z-index: -1;
}

.button-wrapper {
  height: 100%;
  position: relative;
}

.hide {
  background-color: #cacaca;
  color: #575757;
  pointer-events: none;
}

.btnMeInteresa {
  width: max-content;
}

.meInteresa {
  @media (max-width: map-get($breakpoints, "sm")) {
    width: 100%;

    .share__content {
      width: 100%;
    }

    a {
      flex-grow: 1;
    }
  }
}
