@import "../../utils/variables/colors";
@import "../../utils/variables/borders";
@import "../../utils/variables/decoration";
@import "../../utils/variables/fonts";
@import "../../utils/variables/footer";
@import "../../utils/variables/grid";
@import "../../utils/variables/mediaquerys";
@import "../../utils/variables/menu";
@import "../../utils/variables/percentDesc";

$escaped-characters: (
  ("<", "%3c"),
  (">", "%3e"),
  ("#", "%23"),
  ("(", "%28"),
  (")", "%29")
);

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }

  @return $string;
}

@function escape-svg($string) {
  @if str-index($string, "data:image/svg+xml") {
    @each $char, $encoded in $escaped-characters {
      // Do not escape the url brackets
      @if str-index($string, "url(") == 1 {
        $string: url("#{str-replace(str-slice($string, 6, -3), $char, $encoded)}");
      } @else {
        $string: str-replace($string, $char, $encoded);
      }
    }
  }

  @return $string;
}

@import "../../utils/mixins/include-media";
@import "../../utils/mixins/align-text";
@import "../../utils/mixins/apply-increments";
@import "../../utils/mixins/basic-transition";
@import "../../utils/mixins/colors";
@import "../../utils/mixins/event-wrapper";
@import "../../utils/mixins/font-family";
@import "../../utils/mixins/font-size";
@import "../../utils/mixins/grid-padding";
@import "../../utils/mixins/grid-padding-left";
@import "../../utils/mixins/grid-padding-right";
@import "../../utils/mixins/screen-height";
@import "../../utils/mixins/margin-bottom";
@import "../../utils/mixins/padding-left";
@import "../../utils/mixins/rem-converter";
@import "../../utils/mixins/leading-trim";
@import "../../utils/mixins/headings";
@import "../../utils/mixins/underline-effect";
@import "../../utils/mixins/lists";
@import "../../utils/mixins/tables";

// FAMILIES
%bold {
  font-family: "graphikbold";
}

%semibold {
  font-family: "graphiksemibold";
}

%medium {
  font-family: "graphikmedium";
}

%regular {
  font-family: "graphik_regularregular";
}

%light {
  font-family: "graphiklight";
}

// FONT VARIANTS
%bold-italic {
  font-family: "graphikbold_italic";
}

%semibold-italic {
  font-family: "graphiksemibold_italic";
}

%medium-italic {
  font-family: "graphikmedium_italic";
}

%regular-italic {
  font-family: "graphik_regularregular_-";
}

%light-italic {
  font-family: "graphiklight_italic";
}

// FONT-SIZES
%xxl {
  @include fontSize(xxl);
  line-height: 110%;
}
%xl {
  @include fontSize(xl);
  line-height: 110%;
}

%l {
  @include fontSize(l);
  line-height: 120%;
}

%m {
  @include fontSize(m);
  line-height: 110%;
}

%sm {
  @include fontSize(sm);
  line-height: 110%;
}

%xs {
  @include fontSize(body_l);
  line-height: 110%;
}

%body-l {
  @include fontSize(body_l);
  line-height: 120%;
}

%body-m {
  @include fontSize(body_m);
  line-height: var(--line-height);
}

%body-s {
  @include fontSize(body_s);
  line-height: var(--line-height);
}

%body-xs {
  @include fontSize(body_xs);
  line-height: var(--line-height);
}

// PROJECT FONTS
%xxl-medium {
  @extend %xxl;
  @extend %medium;
}
%xl-bold {
  @extend %xl;
  @extend %bold;
}
%xl-medium {
  @extend %xl;
  @extend %medium;
}
%xl-semibold {
  @extend %xl;
  @extend %semibold;
}
%xl-regular {
  @extend %xl;
  @extend %regular;
}

%l-bold {
  @extend %l;
  @extend %bold;
}
%l-semibold {
  @extend %l;
  @extend %semibold;
}
%l-medium {
  @extend %l;
  @extend %medium;
}
%l-regular {
  @extend %l;
  @extend %regular;
}

%m-medium {
  @extend %m;
  @extend %medium;
}
%m-regular {
  @extend %m;
  @extend %regular;
}

%sm-medium {
  @extend %sm;
  @extend %medium;
}

%xs-semibold {
  @extend %xs;
  @extend %semibold;
}

%body-l-semibold {
  @extend %body-l;
  @extend %semibold;
}

%body-l-medium {
  @extend %body-l;
  @extend %medium;
}

%body-l-regular {
  @extend %body-l;
  @extend %regular;
}

%body-m-semibold {
  @extend %body-m;
  @extend %semibold;
}

%body-m-medium {
  @extend %body-m;
  @extend %medium;
}

%body-m-regular {
  @extend %body-m;
  @extend %regular;
}

%body-s-semibold {
  @extend %body-s;
  @extend %semibold;
}

%body-s-medium {
  @extend %body-s;
  @extend %medium;
}

%body-s-regular {
  @extend %body-s;
  @extend %regular;
}

%body-xs-semibold {
  @extend %body-xs;
  @extend %semibold;
  text-transform: uppercase;
}

%body-xs-medium {
  @extend %body-xs;
  @extend %medium;
  text-transform: uppercase;
}

%body-xs-regular {
  @extend %body-xs;
  @extend %regular;
  text-transform: uppercase;
}

%menu {
  @extend %medium;
  font-size: 11px;
  @include media(">=l") {
    font-size: 12px;
  }
  @include media(">=xl") {
    font-size: 13px;
  }
  @include media(">=xxl") {
    font-size: 14px;
  }
  @include media(">=xxxl") {
    font-size: 15px;
  }
  @include media(">=xxxxl") {
    font-size: 16px;
  }
}

%marquee {
  @include fontSize(marquer);
}

%input {
  @include fontSize(body_l);
  color: $secondary-color;
  font-weight: 400;
}

:root {
  --font-size: 14;
  --line-height: #{$line-height};
  --space-56: #{toRem(16)};

  @media (min-width: map-get($breakpoints, "sm")) {
    --space-56: #{toRem(24)};
  }

  @media (min-width: map-get($breakpoints, "md")) {
    --font-size: 16;
    --space-56: #{toRem(40)};
  }

  @media (min-width: map-get($breakpoints, "l")) {
    --space-56: #{toRem(56)};
  }
}

body {
  color: $primary-color;
  @extend %body-m-regular;
}

//$breakpoints: (xs: 320px, sm: 768px, md: 1024px, l: 1280px, xl: 1366px, xxl: 1440px,xxxl: 1680px, xxxxl: 1920px)
$padding-sizes: (
  "xxxxl": 70px,
  "xxxl": 62px,
  "xxl": 40px,
  "xl": 38px,
  "l": 36px,
  "md": 30px,
  "sm": 30px,
  "xs": 15px,
);
$padding-sizes_hero: (
  "xxxxl": 70px,
  "xxxl": 40px,
  "xxl": 40px,
  "xl": 50px,
  "l": 40px,
  "md": 35px,
  "sm": 30px,
  "xs": 15px,
);

$menu-desktop: 90px;
$menu-mobile: 66px;

%block-padding {
  @include gridPadding($padding-sizes);
}

%layout {
  margin-left: 0;
  flex: 1;

  @include media(">=md") {
    margin-left: 92px;
  }
  @include media(">=l") {
    margin-left: 100px;
  }
  @include media(">=xl") {
    margin-left: 110px;
  }
  @include media(">=xxxl") {
    margin-left: 125px;
  }
  @include media(">=xxxxl") {
    margin-left: 140px;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 15px;

  @media (min-width: map-get($breakpoints, "sm")) {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-column-gap: apply-increments(2.25);
  }
}

main {
  @media (max-width: 1023px) {
    > section:first-of-type[class*="BloqueTextoSimple"] {
      padding-top: toRem(40);
    }
  }

  @media (min-width: map-get($breakpoints, "md")) {
    > div:first-of-type {
      & + section[class*="BloqueTextoSimple"] {
        padding-top: toRem(40);
      }
    }
  }

  @media (min-width: map-get($breakpoints, "xl")) {
    > div:first-of-type {
      & + section[class*="BloqueTextoSimple"] {
        padding-top: toRem(120);
      }
    }
  }
}

.col-span-2 {
  grid-column: span 2 / span 2;
}
.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}
.col-span-5 {
  grid-column: span 5 / span 5;
}
.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-start-1 {
  grid-column-start: 1;
}

.col-end-13 {
  grid-column-end: 13;
}

@media (min-width: map-get($breakpoints, "sm")) {
  .flex-layout-sm {
    display: flex;
    flex-wrap: wrap;
  }

  .col-sm-12 {
    flex-basis: 100%;
  }

  .grid-sm {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-column-gap: apply-increments(2.25);
  }
  .col-span-sm-3 {
    grid-column: span 3 / span 3;
  }
  .col-span-sm-4 {
    grid-column: span 4 / span 4;
  }
  .col-span-sm-6 {
    grid-column: span 6 / span 6;
  }
  .col-span-sm-8 {
    grid-column: span 8 / span 8;
  }
  .col-span-sm-10 {
    grid-column: span 10 / span 10;
  }
  .col-span-sm-12 {
    grid-column: span 12 / span 12;
  }
  .col-sm-start-2 {
    grid-column-start: 2;
  }
  .col-sm-start-3 {
    grid-column-start: 3;
  }
}

@media (min-width: map-get($breakpoints, "md")) {
  .grid-md {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-column-gap: apply-increments(2.25);
  }

  .col-span-md-3 {
    grid-column: span 3 / span 3;
  }
  .col-span-md-9 {
    grid-column: span 9 / span 9;
  }
  .col-span-md-6 {
    grid-column: span 6 / span 6;
  }

  .col-md-start-6 {
    grid-column-start: 3;
  }
  .col-span-md-6 {
    grid-column: span 6 / span 6;
  }
  .col-span-md-8 {
    grid-column: span 8 / span 8;
  }
}

@media (min-width: map-get($breakpoints, "l")) {
  .grid-l {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-column-gap: apply-increments(2.25);
  }

  .col-l-start-2 {
    grid-column-start: 2;
  }

  .col-l-end-12 {
    grid-column-end: 12;
  }
  .col-span-l-9 {
    grid-column: span 9 / span 9;
  }
  .col-span-l-3 {
    grid-column: span 3 / span 3;
  }
}

@media (min-width: map-get($breakpoints, "xl")) {
  .col-xl-start-3 {
    grid-column-start: 3;
  }
  .col-span-xxl-2 {
    grid-column: span 2 / span 2;
  }
  .col-xl-end-11 {
    grid-column-end: 11;
  }
  .col-span-xl-9 {
    grid-column: span 9 / span 9;
  }
  .col-span-xl-3 {
    grid-column: span 3 / span 3;
  }
}

@media (min-width: map-get($breakpoints, "xxxl")) {
  .col-span-xxl-2 {
    grid-column: span 2 / span 2;
  }
  .col-span-xxl-2 {
    grid-column: span 2 / span 2;
  }
}

@media (min-width: map-get($breakpoints, "xxxxl")) {
  .align-items-xxxxl-center {
    align-items: center;
  }

  .grid-xxxxl {
    display: grid;
  }

  .grid,
  .grid-sm,
  .grid-md,
  .grid-l,
  .grid-xxxxl {
    grid-template-columns: repeat(24, minmax(0, 1fr));
    grid-column-gap: 40px;
  }
  .col-span-xxxxl-2 {
    grid-column: span 2 / span 2;
  }
  .col-span-xxxxl-4 {
    grid-column: span 4 / span 4;
  }
  .col-span-xxxxl-6 {
    grid-column: span 6 / span 6;
  }
  .col-span-xxxxl-8 {
    grid-column: span 8 / span 8;
  }
  .col-span-xxxxl-9 {
    grid-column: span 9 / span 9;
  }
  .col-span-xxxxl-10 {
    grid-column: span 10 / span 10;
  }
  .col-span-xxxxl-12 {
    grid-column: span 12 / span 12;
  }
  .col-xxxxl-end-20 {
    grid-column-end: 20;
  }
  .col-xxxxl-start-2 {
    grid-column-start: 2;
  }
  .col-xxxxl-start-6 {
    grid-column-start: 6;
  }

  .col-xxxxl-start-7 {
    grid-column-start: 7;
  }
  .col-xxxxl-start-8 {
    grid-column-start: 8;
  }
  .col-xxxxl-start-13 {
    grid-column-start: 13;
  }
  .col-xxxxl-start-14 {
    grid-column-start: 14;
  }
  .col-span-xxxxl-24 {
    grid-column: span 24 / span 24;
  }
  .col-span-xxxl-5 {
    grid-column: span 5 / span 5;
  }
  .col-span-xxxl-6 {
    grid-column: span 6 / span 6;
  }
  .col-span-xxxl-8 {
    grid-column: span 18 / span 18;
  }
  .col-span-xxxl-9 {
    grid-column: span 19 / span 19;
  }
  .col-xxxl-end-20 {
    grid-column-end: 20;
  }
  .col-xxxl-start-20 {
    grid-column-start: 20;
  }
}

@mixin visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.obfuscate {
  direction: ltr !important;
}

.featured-text {
  font-family: "graphiksemibold";
  font-size: toRem(16);
  font-weight: normal;
  line-height: var(--line-height);

  @media (min-width: map-get($breakpoints, "xxxxl")) {
    font-size: toRem(18);
  }
}

%before-opacity {
  &::before {
    content: "";
    background-color: rgba($primary-color, 0.2);
    inset: 0;
    position: absolute;
    z-index: 1;
  }
}

%wysiwyg-16 {
  > * {
    margin-bottom: 0;
  }

  > * + * {
    margin-top: toRem(16);
  }
}

.wysiwyg {
  img {
    display: block;
    height: auto;
    max-width: 100%;

    &:not([class]) {
      display: inline-block;
    }
  }

  figure {
    display: table;
    position: relative;

    img {
      display: block !important;
    }
  }

  figcaption {
    bottom: 0;
    position: absolute;
    width: 100%;
    text-align: center;
    color: $secondary-color;
    padding: apply-increments(2);
  }

  .align-left {
    float: left;
    padding-right: apply-increments(3);
    padding-bottom: apply-increments(3);
  }

  .align-center {
    margin-left: auto;
    margin-right: auto;
  }

  .align-right {
    float: right;
    padding-left: apply-increments(3);
    padding-bottom: apply-increments(3);
  }
}

@import "variables.scss";
@import "mixins.scss";
@import "../../utils/mixins/rem-converter";

.react-datepicker-wrapper {
  padding: 0;
  border: 0;
}

.react-datepicker {
  font-size: $datepicker__font-size;
  background-color: $secondary-color;
  color: $datepicker__text-color;
  display: inline-block;
  position: relative;
  border: 1px solid $line;
  padding: 0 1rem 1rem 1rem;
  border-radius: 3px;

  // Reverting value set in .react-datepicker-popper
  line-height: initial;
}

.react-datepicker--time-only {
  .react-datepicker__time-container {
    border-left: 0;
  }

  .react-datepicker__time,
  .react-datepicker__time-box {
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
}

.react-datepicker-popper {
  z-index: 1;

  // Eliminating extra space at the bottom of the container
  line-height: 0;

  &[data-placement^="bottom"] {
    .react-datepicker__triangle {
      @extend %triangle-arrow-up;
    }
  }

  &[data-placement^="top"] {
    .react-datepicker__triangle {
      @extend %triangle-arrow-down;
    }
  }
}

.react-datepicker__header {
  text-align: center;
  padding: toRem(22) 0 0 0;
  position: relative;

  &--time {
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px;

    &:not(&--only) {
      border-top-left-radius: 0;
    }
  }

  &:not(&--has-time-select) {
    border-top-right-radius: $datepicker__border-radius;
  }
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 15px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: $datepicker__header-color;
  font-weight: bold;
  font-size: $datepicker__font-size * 1.18;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  align-items: center;
  background: none;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: toRem(18);
  padding: 0;
  border: none;
  z-index: 1;
  height: $datepicker__navigation-button-size;
  width: $datepicker__navigation-button-size;

  &::before {
    @extend %navigation-chevron;
  }

  &--previous {
    left: 0;

    &::before {
      transform: rotate(180deg);
    }
  }

  &--next {
    right: 0;

    &--with-time:not(&--with-today-button) {
      right: 85px;
    }
  }

  &--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &-previous {
      top: 4px;
    }

    &-upcoming {
      top: -4px;
    }
  }

  &:hover {
    *::before {
      border-color: darken($datepicker__muted-color, 15%);
    }
  }
}

.react-datepicker__navigation-icon {
  @include visually-hidden;

  /*  position: relative;
  font-size: 20px;
  width: 0;

  &::before {
    @extend %navigation-chevron;
  }

  &--next {
    left: -2px;
  }

  &--previous {
    right: -2px;
  } */
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__year {
  margin: $datepicker__margin;
  text-align: center;

  &-wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 180px;
  }

  .react-datepicker__year-text {
    display: inline-block;
    width: 4rem;
    margin: 2px;
  }
}

.react-datepicker__month {
  //margin: $datepicker__margin;
  text-align: center;

  .react-datepicker__day {
    border: 1px solid $line;
  }

  .react-datepicker__month-text,
  .react-datepicker__quarter-text {
    display: inline-block;
    width: 4rem;
    margin: 2px;
  }
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;

  .react-datepicker-time__caption {
    display: inline-block;
  }

  .react-datepicker-time__input-container {
    display: inline-block;

    .react-datepicker-time__input {
      display: inline-block;
      margin-left: 10px;

      input {
        width: auto;
      }

      input[type="time"]::-webkit-inner-spin-button,
      input[type="time"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type="time"] {
        -moz-appearance: textfield;
      }
    }

    .react-datepicker-time__delimiter {
      margin-left: 5px;
      display: inline-block;
    }
  }
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid $datepicker__border-color;
  width: 85px;

  &--with-today-button {
    display: inline;
    border: 1px solid $line;
    border-radius: 0.3rem;
    position: absolute;
    right: -87px;
    top: 0;
  }

  .react-datepicker__time {
    position: relative;
    background: $secondary-color;
    border-bottom-right-radius: 0.3rem;

    .react-datepicker__time-box {
      width: 85px;
      overflow-x: hidden;
      margin: 0 auto;
      text-align: center;
      border-bottom-right-radius: 0.3rem;

      ul.react-datepicker__time-list {
        list-style: none;
        margin: 0;
        height: calc(195px + (#{$datepicker__item-size} / 2));
        overflow-y: scroll;
        padding-right: 0;
        padding-left: 0;
        width: 100%;
        box-sizing: content-box;

        li.react-datepicker__time-list-item {
          height: 30px;
          padding: 5px 10px;
          white-space: nowrap;

          &:hover {
            cursor: pointer;
            background-color: $datepicker__background-color;
          }

          &--selected {
            background-color: $datepicker__selected-color;
            color: $secondary-color;

            &:hover {
              background-color: $datepicker__selected-color;
            }
          }

          &--disabled {
            color: $datepicker__muted-color;

            &:hover {
              cursor: default;
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}

.react-datepicker__week-number {
  color: $datepicker__muted-color;
  display: inline-block;
  width: $datepicker__item-size;
  line-height: $datepicker__item-size;
  text-align: center;
  margin: $datepicker__day-margin;

  &.react-datepicker__week-number--clickable {
    cursor: pointer;

    &:not(
        .react-datepicker__week-number--selected,
        .react-datepicker__week-number--keyboard-selected
      ):hover {
      background-color: $datepicker__background-color;
    }
  }

  &--selected {
    background-color: $datepicker__selected-color;
    color: $secondary-color;

    &:hover {
      background-color: darken($datepicker__selected-color, 5%);
    }
  }

  &--keyboard-selected {
    background-color: lighten($datepicker__selected-color, 10%);
    color: $secondary-color;

    &:hover {
      background-color: darken($datepicker__selected-color, 5%);
    }
  }
}

.react-datepicker__day-names {
  padding-top: toRem(10);
  white-space: nowrap;
  color: $text-color-secondary;
  text-transform: uppercase;
  font-size: toRem(10);
}

.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  //color: $datepicker__text-color;
  display: inline-block;
  width: $datepicker__item-size;
  line-height: $datepicker__item-size;
  text-align: center;
  //margin: $datepicker__day-margin;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer;

  &:hover {
    background-color: $datepicker__background-color;
  }

  &--today {
    font-weight: bold;
  }

  &--highlighted {
    background-color: $datepicker__highlighted-color;
    color: $secondary-color;

    &:hover {
      background-color: darken($datepicker__highlighted-color, 5%);
    }

    &-custom-1 {
      color: magenta;
    }

    &-custom-2 {
      color: green;
    }
  }

  &--holidays {
    position: relative;
    background-color: $datepicker__holidays-color;
    color: $secondary-color;

    .overlay {
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      background-color: #333;
      color: $secondary-color;
      padding: 4px;
      white-space: nowrap;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s, opacity 0.3s ease-in-out;
    }

    &:hover {
      background-color: darken($datepicker__holidays-color, 10%);
    }

    &:hover .overlay {
      visibility: visible;
      opacity: 1;
    }
  }

  &--selected,
  &--in-selecting-range,
  &--in-range {
    background-color: $datepicker__selected-color;
    color: $secondary-color;

    &:hover {
      background-color: darken($datepicker__selected-color, 5%);
    }
  }

  &--keyboard-selected {
    background-color: $datepicker__selected-color;
    color: $secondary-color;

    &:hover {
      background-color: darken($datepicker__selected-color, 5%);
    }
  }

  &--in-selecting-range:not(&--in-range) {
    background-color: rgba($datepicker__selected-color, 0.5);
  }

  &--in-range:not(&--in-selecting-range) {
    .react-datepicker__month--selecting-range &,
    .react-datepicker__year--selecting-range & {
      background-color: $datepicker__background-color;
      color: $datepicker__text-color;
    }
  }

  &--disabled {
    cursor: default;
    color: $datepicker__muted-color;

    &:hover {
      background-color: transparent;
    }

    .overlay {
      position: absolute;
      bottom: 70%;
      left: 50%;
      transform: translateX(-50%);
      background-color: #333;
      color: $secondary-color;
      padding: 4px;
      white-space: nowrap;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s, opacity 0.3s ease-in-out;
    }
  }
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;

  .react-datepicker__calendar-icon {
    position: absolute;
    padding: 0.5rem;
    box-sizing: content-box;
  }
}

.react-datepicker__view-calendar-icon {
  input {
    padding: 6px 10px 5px 25px;
  }
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  position: relative;

  &:hover {
    cursor: pointer;

    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow {
      border-top-color: darken($datepicker__muted-color, 10%);
    }
  }

  &--down-arrow {
    @extend %navigation-chevron;

    transform: rotate(135deg);
    right: -16px;
    top: 0;
  }
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: $datepicker__background-color;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border: 1px solid $datepicker__border-color;

  &:hover {
    cursor: pointer;
  }

  &--scrollable {
    height: 150px;
    overflow-y: scroll;
  }
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:first-of-type {
    border-top-left-radius: $datepicker__border-radius;
    border-top-right-radius: $datepicker__border-radius;
  }

  &:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: $datepicker__border-radius;
    border-bottom-right-radius: $datepicker__border-radius;
  }

  &:hover {
    background-color: $datepicker__muted-color;

    .react-datepicker__navigation--years-upcoming {
      border-bottom-color: darken($datepicker__muted-color, 10%);
    }

    .react-datepicker__navigation--years-previous {
      border-top-color: darken($datepicker__muted-color, 10%);
    }
  }

  &--selected {
    position: absolute;
    left: 15px;
  }
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;

  &::after {
    cursor: pointer;
    background-color: $datepicker__selected-color;
    color: $secondary-color;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding: 2px;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: "\00d7";
  }

  &--disabled {
    cursor: default;

    &::after {
      cursor: default;
      background-color: $datepicker__muted-color;
    }
  }
}

.react-datepicker__today-button {
  background: $datepicker__background-color;
  border-top: 1px solid $datepicker__border-color;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 3rem;
    line-height: 3rem;
  }

  @media (max-width: 400px), (max-height: 550px) {
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      width: 2rem;
      line-height: 2rem;
    }
  }
}

.react-datepicker__current-month {
  color: #484848;
  font-size: toRem(18);
  line-height: calc(24 / 18);
}

.react-datepicker__children-container {
  width: 13.8rem;
  margin: 0.4rem;
  padding-right: 0.2rem;
  padding-left: 0.2rem;
  height: auto;
}

.react-datepicker__aria-live {
  @include visually-hidden;
}

.react-datepicker__calendar-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.125em;
}

.react-datepicker__day {
  margin: -1px 0 0 -1px;

  &:not(&--keyboard-selected):hover {
    background-color: $line;
  }

  &:focus-visible {
    outline: none;
  }
}

.react-datepicker__day-name {
  line-height: calc(11 / 10);
  padding-block: toRem(8);
}
