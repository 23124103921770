:root {
  --font-size: 14;
  --line-height: #{$line-height};
  --space-56: #{toRem(16)};

  @media (min-width: map-get($breakpoints, "sm")) {
    --space-56: #{toRem(24)};
  }

  @media (min-width: map-get($breakpoints, "md")) {
    --font-size: 16;
    --space-56: #{toRem(40)};
  }

  @media (min-width: map-get($breakpoints, "l")) {
    --space-56: #{toRem(56)};
  }
}

body {
  color: $primary-color;
  @extend %body-m-regular;
}

//$breakpoints: (xs: 320px, sm: 768px, md: 1024px, l: 1280px, xl: 1366px, xxl: 1440px,xxxl: 1680px, xxxxl: 1920px)
$padding-sizes: (
  "xxxxl": 70px,
  "xxxl": 62px,
  "xxl": 40px,
  "xl": 38px,
  "l": 36px,
  "md": 30px,
  "sm": 30px,
  "xs": 15px,
);
$padding-sizes_hero: (
  "xxxxl": 70px,
  "xxxl": 40px,
  "xxl": 40px,
  "xl": 50px,
  "l": 40px,
  "md": 35px,
  "sm": 30px,
  "xs": 15px,
);

$menu-desktop: 90px;
$menu-mobile: 66px;

%block-padding {
  @include gridPadding($padding-sizes);
}

%layout {
  margin-left: 0;
  flex: 1;

  @include media(">=md") {
    margin-left: 92px;
  }
  @include media(">=l") {
    margin-left: 100px;
  }
  @include media(">=xl") {
    margin-left: 110px;
  }
  @include media(">=xxxl") {
    margin-left: 125px;
  }
  @include media(">=xxxxl") {
    margin-left: 140px;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 15px;

  @media (min-width: map-get($breakpoints, "sm")) {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-column-gap: apply-increments(2.25);
  }
}

main {
  @media (max-width: 1023px) {
    > section:first-of-type[class*="BloqueTextoSimple"] {
      padding-top: toRem(40);
    }
  }

  @media (min-width: map-get($breakpoints, "md")) {
    > div:first-of-type {
      & + section[class*="BloqueTextoSimple"] {
        padding-top: toRem(40);
      }
    }
  }

  @media (min-width: map-get($breakpoints, "xl")) {
    > div:first-of-type {
      & + section[class*="BloqueTextoSimple"] {
        padding-top: toRem(120);
      }
    }
  }
}

.col-span-2 {
  grid-column: span 2 / span 2;
}
.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}
.col-span-5 {
  grid-column: span 5 / span 5;
}
.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-start-1 {
  grid-column-start: 1;
}

.col-end-13 {
  grid-column-end: 13;
}

@media (min-width: map-get($breakpoints, "sm")) {
  .flex-layout-sm {
    display: flex;
    flex-wrap: wrap;
  }

  .col-sm-12 {
    flex-basis: 100%;
  }

  .grid-sm {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-column-gap: apply-increments(2.25);
  }
  .col-span-sm-3 {
    grid-column: span 3 / span 3;
  }
  .col-span-sm-4 {
    grid-column: span 4 / span 4;
  }
  .col-span-sm-6 {
    grid-column: span 6 / span 6;
  }
  .col-span-sm-8 {
    grid-column: span 8 / span 8;
  }
  .col-span-sm-10 {
    grid-column: span 10 / span 10;
  }
  .col-span-sm-12 {
    grid-column: span 12 / span 12;
  }
  .col-sm-start-2 {
    grid-column-start: 2;
  }
  .col-sm-start-3 {
    grid-column-start: 3;
  }
}

@media (min-width: map-get($breakpoints, "md")) {
  .grid-md {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-column-gap: apply-increments(2.25);
  }

  .col-span-md-3 {
    grid-column: span 3 / span 3;
  }
  .col-span-md-9 {
    grid-column: span 9 / span 9;
  }
  .col-span-md-6 {
    grid-column: span 6 / span 6;
  }

  .col-md-start-6 {
    grid-column-start: 3;
  }
  .col-span-md-6 {
    grid-column: span 6 / span 6;
  }
  .col-span-md-8 {
    grid-column: span 8 / span 8;
  }
}

@media (min-width: map-get($breakpoints, "l")) {
  .grid-l {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-column-gap: apply-increments(2.25);
  }

  .col-l-start-2 {
    grid-column-start: 2;
  }

  .col-l-end-12 {
    grid-column-end: 12;
  }
  .col-span-l-9 {
    grid-column: span 9 / span 9;
  }
  .col-span-l-3 {
    grid-column: span 3 / span 3;
  }
}

@media (min-width: map-get($breakpoints, "xl")) {
  .col-xl-start-3 {
    grid-column-start: 3;
  }
  .col-span-xxl-2 {
    grid-column: span 2 / span 2;
  }
  .col-xl-end-11 {
    grid-column-end: 11;
  }
  .col-span-xl-9 {
    grid-column: span 9 / span 9;
  }
  .col-span-xl-3 {
    grid-column: span 3 / span 3;
  }
}

@media (min-width: map-get($breakpoints, "xxxl")) {
  .col-span-xxl-2 {
    grid-column: span 2 / span 2;
  }
  .col-span-xxl-2 {
    grid-column: span 2 / span 2;
  }
}

@media (min-width: map-get($breakpoints, "xxxxl")) {
  .align-items-xxxxl-center {
    align-items: center;
  }

  .grid-xxxxl {
    display: grid;
  }

  .grid,
  .grid-sm,
  .grid-md,
  .grid-l,
  .grid-xxxxl {
    grid-template-columns: repeat(24, minmax(0, 1fr));
    grid-column-gap: 40px;
  }
  .col-span-xxxxl-2 {
    grid-column: span 2 / span 2;
  }
  .col-span-xxxxl-4 {
    grid-column: span 4 / span 4;
  }
  .col-span-xxxxl-6 {
    grid-column: span 6 / span 6;
  }
  .col-span-xxxxl-8 {
    grid-column: span 8 / span 8;
  }
  .col-span-xxxxl-9 {
    grid-column: span 9 / span 9;
  }
  .col-span-xxxxl-10 {
    grid-column: span 10 / span 10;
  }
  .col-span-xxxxl-12 {
    grid-column: span 12 / span 12;
  }
  .col-xxxxl-end-20 {
    grid-column-end: 20;
  }
  .col-xxxxl-start-2 {
    grid-column-start: 2;
  }
  .col-xxxxl-start-6 {
    grid-column-start: 6;
  }

  .col-xxxxl-start-7 {
    grid-column-start: 7;
  }
  .col-xxxxl-start-8 {
    grid-column-start: 8;
  }
  .col-xxxxl-start-13 {
    grid-column-start: 13;
  }
  .col-xxxxl-start-14 {
    grid-column-start: 14;
  }
  .col-span-xxxxl-24 {
    grid-column: span 24 / span 24;
  }
  .col-span-xxxl-5 {
    grid-column: span 5 / span 5;
  }
  .col-span-xxxl-6 {
    grid-column: span 6 / span 6;
  }
  .col-span-xxxl-8 {
    grid-column: span 18 / span 18;
  }
  .col-span-xxxl-9 {
    grid-column: span 19 / span 19;
  }
  .col-xxxl-end-20 {
    grid-column-end: 20;
  }
  .col-xxxl-start-20 {
    grid-column-start: 20;
  }
}
