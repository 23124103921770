$codeFontFamily: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
@font-face {
  font-family: "graphikbold";
  src: url("../../font/graphik-bold-webfont.woff2") format("woff2"),
    url("../../font/graphik-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "graphikbold_italic";
  src: url("../../font/graphik-bolditalic-webfont.woff2") format("woff2"),
    url("../../font/graphik-bolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "graphiklight";
  src: url("../../font/graphik-light-webfont.woff2") format("woff2"),
    url("../../font/graphik-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "graphiklight_italic";
  src: url("../../font/graphik-lightitalic-webfont.woff2") format("woff2"),
    url("../../font/graphik-lightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "graphikmedium";
  src: url("../../font/graphik-medium-webfont.woff2") format("woff2"),
    url("../../font/graphik-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "graphikmedium_italic";
  src: url("../../font/graphik-mediumitalic-webfont.woff2") format("woff2"),
    url("../../font/graphik-mediumitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "graphik_regularregular";
  src: url("../../font/graphik-regular-webfont.woff2") format("woff2"),
    url("../../font/graphik-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "graphiksemibold";
  src: url("../../font/graphik-semibold-webfont.woff2") format("woff2"),
    url("../../font/graphik-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "graphiksemibold_italic";
  src: url("../../font/graphik-semibolditalic-webfont.woff2") format("woff2"),
    url("../../font/graphik-semibolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

$font-size-options: (
  xxl: (
    400px,
    300px,
    300px,
    250px,
    200px,
    150px,
    100px,
  ),
  xl: (
    64px,
    56px,
    56px,
    48px,
    48px,
    40px,
    32px,
  ),
  l: (
    48px,
    40px,
    40px,
    32px,
    24px,
    24px,
    24px,
  ),
  m: (
    32px,
    32px,
    32px,
    24px,
    24px,
    24px,
    18px,
  ),
  sm: (
    32px,
    32px,
    32px,
    32px,
    32px,
    32px,
    24px,
  ),
  body_l: (
    18px,
    18px,
    18px,
    18px,
    18px,
    16px,
    16px,
  ),
  body_m: (
    16px,
    16px,
    16px,
    16px,
    16px,
    14px,
    14px,
  ),
  body_s: (
    14px,
    14px,
    14px,
    14px,
    13px,
    13px,
    13px,
  ),
  body_xs: (
    14px,
    14px,
    14px,
    14px,
    14px,
    12px,
    12px,
  ),
  marquer: (
    14px,
    13px,
    11px,
    11px,
    11px,
    10px,
    12px,
  ),
);

$line-height: 150%;
