@mixin lists {
  --line-height--block: 150;

  ul {
    > li {
      padding-left: toRem(17);
      position: relative;
    }
  }

  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }

  ul > li {
    &:before {
      --real-line-height: calc(
        (var(--line-height--block) * var(--font-size)) / 100
      );
      --icon-size: 5;
      background-color: $primary-color;
      border-radius: 50%;
      content: "";
      display: block;
      height: toRem(5);
      left: 0;
      position: absolute;
      top: calc(((var(--real-line-height) - var(--icon-size)) / 2) * 1px);
      width: toRem(5);
    }
  }

  li {
    > ul,
    > ol {
      padding-left: toRem(17);
    }

    ul,
    ol {
      margin-top: toRem(16);
    }

    + * {
      margin-top: toRem(8);
    }
  }

  * + ul,
  * + ol {
    margin-top: toRem(24);
  }

  @media (min-width: map-get($breakpoints, "sm")) {
    li {
      + * {
        margin-top: toRem(16);
      }
    }
  }
}

@mixin custom-list(
  $icon-width,
  $icon-height,
  $font-size,
  $line-height,
  $distance,
  $padding-left: 19px,
  $icon-color: null,
  $icon-img: null
) {
  ul {
    > li {
      @if $padding-left != null {
        padding-left: $padding-left;
      } @else {
        padding-left: 19px;
      }
      position: relative;

      &::before {
        @if $icon-color != null {
          background-color: $icon-color;
        }
        @if $icon-img != null {
          background-image: #{escape-svg($icon-img)};
        }
        border-radius: 50%;
        content: "";
        height: $icon-height;
        left: 0;
        position: absolute;
        top: calc((#{$line-height} * #{$font-size} - #{$icon-height}) / 2);
        width: $icon-width;
      }

      + * {
        margin-top: $distance;
      }
    }
  }

  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }

  li {
    ul,
    ol {
      margin-top: $distance;
    }

    + * {
      margin-top: $distance;
    }
  }
}
