/**
 * Scroll suave
 */
html {
  scroll-behavior: smooth;
}

/**
 * Box model decente para el root
 */
html,
body {
  box-sizing: border-box;
  max-width: 100vw;
  margin: 0;
  //overflow-x: hidden;
  padding: 0;
}

/**
 * Hace que todos los elementos del DOM herenden del padre el box-sizing
 * Como `*` tiene una especificidad de 0, no sobreescribe el valor del html
 * haciendo que todos los elementos lo herenden desde el root
 * Ver: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
  * Antialiased: fuentes bellas
 */
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/**
  * SelecciÃ³n de texto
 */
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  -webkit-tap-highlight-color: transparent;
}

/**
 * Elimina outlines en el focus
*/

*::selection,
*::-moz-selection {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/**
 * Elimina el top-margin del texto
*/
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
}

:is(h1, h2, h3, h4, h5, h6) {
  @extend %medium;
  font-weight: normal;
}

/**
 * Normaliza el address
 */
address {
  font-style: normal;
}

/**
 * Normaliza el figure
 */
figure {
  padding: 0;
  margin: 0;
}

/**
 * Normalize ol y ul
 */
ul,
ol {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  list-style: none;
  margin: 0;
  padding: 0;
  & li {
    margin-top: 0;
  }
}

/**
 * Link
 */

a {
  @include basicTransition(opacity);
  color: inherit;
  text-decoration: none;
}

/**
  * Button
 */
button {
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  margin: 0;
  overflow: visible;
}

/**
* Table
*/
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  text-align: left;
  vertical-align: top;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
* Fielset
 */

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
  min-width: 0;
}
