/// Crea los márgenes necesarios a los lados según diseño
/// @authoer WGI
/// @param {Array} $size
/// @return {padding}

@mixin gridPadding($size) {
  $size-main: map-get($size, "xxxxl");
  $size-xxx: map-get($size, "xxxl");
  $size-xx: map-get($size, "xxl");
  $size-xl: map-get($size, "xl");
  $size-l: map-get($size, "l");

  $size-md: map-get($size, "md");
  $size-sm: map-get($size, "sm");
  $size-xs: map-get($size, "xs");

  padding-left: $size-xs;
  padding-right: $size-xs;

  @include media(">=sm") {
    padding-left: $size-sm;
    padding-right: $size-sm;
  }
  @include media(">=md") {
    padding-left: $size-md;
    padding-right: $size-md;
  }
  @include media(">=l") {
    padding-left: $size-l;
    padding-right: $size-l;
  }
  @include media(">=xl") {
    padding-left: $size-xl;
    padding-right: $size-xl;
  }
  @include media(">=xxl") {
    padding-left: $size-xx;
    padding-right: $size-xx;
  }
  @include media(">=xxxl") {
    padding-left: $size-xxx;
    padding-right: $size-xxx;
  }
  @include media(">=xxxxl") {
    padding-left: $size-main;
    padding-right: $size-main;
  }
}
