/// Crea fuentes en responsive
/// @param {Array} $size
/// @return {font-size: nnpx}

@mixin fontSize($size) {
  @include fontSizeResponsive($size);
}
//$breakpoints: (xs: 320px, sm: 768px, md: 1024px, l: 1280px, xl: 1366px, xxl: 1440px,xxxl: 1680px, xxxxl: 1920px)
@mixin fontSizeResponsive($size) {
  $sizeDefined: map-has-key($font-size-options, $size);
  @if ($sizeDefined) {
    $sizes: map-get($font-size-options, $size);
    $size-main: nth($sizes, 1);
    $size-xxxl: nth($sizes, 1);
    $size-xxl: nth($sizes, 2);
    $size-xl: nth($sizes, 3);
    $size-l: nth($sizes, 4);
    $size-md: nth($sizes, 5);
    $size-sm: nth($sizes, 6);
    $size-xs: nth($sizes, 7);

    font-size: $size-xs;
    @include media(">=sm") {
      font-size: $size-sm;
    }
    @include media(">=md") {
      font-size: $size-md;
    }
    @include media(">=l") {
      font-size: $size-l;
    }
    @include media(">=xl") {
      font-size: $size-xl;
    }
    @include media(">=xxl") {
      font-size: $size-xxl;
    }
    @include media(">=xxxl") {
      font-size: $size-xxxl;
    }
    @include media(">=xxxxl") {
      font-size: $size-main;
    }
  }
}
