$primary-color: #1a1a1a;
$secondary-color: #fff;
$text-color: $primary-color;
$text-color-secondary: #7C7C7C;
$decoration: #1a1a1a;
$border-color: $decoration;

$icon: #b4b4b4;
$line: #DBDBDB;
$bg: #F4F4F4;
$overlay: rgba($decoration, 0.5);

$error: #dd380f;
$error-secondary: #e77457;
$error-line: #ee9b87;

$comb1: #f18c76;
$comb2: #22b2a6;
$comb3: #e5e5e5;
$comb4: #d7c5be;
$comb5: #fff198;
$comb6: #7794b1;
$comb7: #dadada;
$comb8: #d7e5d5;

$comb1_2: #9bb3dd;
$comb2_2: #d3de77;
$comb3_2: #c2a1cc;
$comb4_2: #d75e6c;
$comb5_2: #c8e6e4;
$comb6_2: #c9d75b;
$comb7_2: #ffcb8e;
$comb8_2: #ececec;

$colors: (
  "black": $primary-color,
  "white": $secondary-color,
);
