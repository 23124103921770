$datepicker__background-color: $secondary-color !default;
$datepicker__border-color: $line !default;
$datepicker__highlighted-color: #3dcc4a !default;
$datepicker__holidays-color: #ff6803 !default;
$datepicker__muted-color: #ccc !default;
$datepicker__selected-color: $primary-color !default;
$datepicker__text-color: $primary-color !default;
$datepicker__header-color: $primary-color !default;
$datepicker__navigation-disabled-color: lighten(
  $datepicker__muted-color,
  10%
) !default;
$datepicker__border-radius: 0.3rem !default;
$datepicker__day-margin: 0.166rem !default;
$datepicker__font-size: 0.75rem !default;
$datepicker__item-size: 2.5rem !default;
$datepicker__margin: 0.4rem !default;
$datepicker__navigation-button-size: 2rem !default;
$datepicker__triangle-size: 8px !default;
