%wysiwyg-16 {
  > * {
    margin-bottom: 0;
  }

  > * + * {
    margin-top: toRem(16);
  }
}

.wysiwyg {
  img {
    display: block;
    height: auto;
    max-width: 100%;

    &:not([class]) {
      display: inline-block;
    }
  }

  figure {
    display: table;
    position: relative;

    img {
      display: block !important;
    }
  }

  figcaption {
    bottom: 0;
    position: absolute;
    width: 100%;
    text-align: center;
    color: $secondary-color;
    padding: apply-increments(2);
  }

  .align-left {
    float: left;
    padding-right: apply-increments(3);
    padding-bottom: apply-increments(3);
  }

  .align-center {
    margin-left: auto;
    margin-right: auto;
  }

  .align-right {
    float: right;
    padding-left: apply-increments(3);
    padding-bottom: apply-increments(3);
  }
}
